export const DefaultData = {
  loader: false,
  error: false,
  registerMonths: {
    months: '',
    optionsMonths: '',
  },
  carData: {
    year: '',
    month: '',
    monthIndex: '',
    brands: '',
    brand: '',
    models: '',
    model: '',
    modelName: '',
    fuels: '',
    fuel: '',
    carModels: '',
    car: '',
    mileage: '',
  },
  userData: {
    name: '',
    surname: '',
    email: '',
    phone: '',
    buyCar: '',
    newCar: '',
    owu: false,
    rEmail: false,
    rPhone: false,
    rSms: false,
    showNewCars: false,
  },
  dealerData: {
    dealers: '',
    dealer: '',
  },
  stepsNextButtons: {
    buttonStep1: false,
    buttonStep2: false,
    buttonStep3: false,
    buttonStep4: false,
    buttonStep5: false,
    buttonStep6: false,
    buttonStep7: false
  },
};

export const DataReducer = (data, { type, payload }) => {
  switch (type) {
    case 'UPDATE_LOADER':
      return {
        ...data,
        ...payload,
      };
    case 'UPDATE_ERROR':
      return {
        ...data,
        ...payload,
      };
    case 'UPDATE_REGISTER_MONTHS':
      return {
        ...data,
        registerMonths: {
          ...payload,
        }
      };
    case 'UPDATE_CAR_DATA':
      return {
        ...data,
        error: false,
        carData: {
          ...data.carData,
          ...payload,
        },
      };
    case 'CHANGE_YEAR':
      return {
        ...data,
        error: false,
        carData: {
          ...data.carData,
          month: '',
          monthIndex: '',
          brands: '',
          brand: '',
          models: '',
          model: '',
          modelName: '',
          fuels: '',
          fuel: '',
          carModels: '',
          car: '',
          mileage: '',
        },
      };
    case 'CHANGE_MONTH':
      return {
        ...data,
        error: false,
        carData: {
          ...data.carData,
          brands: '',
          brand: '',
          models: '',
          model: '',
          modelName: '',
          fuels: '',
          fuel: '',
          carModels: '',
          car: '',
          mileage: '',
        },
      };
    case 'CHANGE_BRAND':
      return {
        ...data,
        error: false,
        carData: {
          ...data.carData,
          models: '',
          model: '',
          modelName: '',
          fuels: '',
          fuel: '',
          carModels: '',
          car: '',
          mileage: '',
        },
      };
    case 'CHANGE_MODEL':
      return {
        ...data,
        error: false,
        carData: {
          ...data.carData,
          fuels: '',
          fuel: '',
          carModels: '',
          car: '',
          mileage: '',
        },
      };
    case 'CHANGE_FUEL':
      return {
        ...data,
        error: false,
        carData: {
          ...data.carData,
          carModels: '',
          car: '',
          mileage: '',
        },
      };
    case 'UPDATE_USER_DATA':
      return {
        ...data,
        error: false,
        userData: {
          ...data.userData,
          ...payload,
        },
      };
    case 'UPDATE_DEALER_DATA':
      return {
        ...data,
        error: false,
        dealerData: {
          ...data.dealerData,
          ...payload,
        },
      };
    case 'CLEAR_DEALER_DATA':
      return {
        ...data,
        error: false,
        dealerData: {
          dealers: '',
          dealer: '',
        }
      };
    case 'UPDATE_NEXT_BUTTON':
      return {
        ...data,
        stepsNextButtons: {
          ...data.stepsNextButtons,
          ...payload,
        },
      };

    default:
      return data
  }
};
