import styled from 'styled-components';
import { breakpoints } from "../../commons/breakpoints";
import PropTypes from "prop-types";

const WrapperHeader = styled.header`
  display: flex;
  align-items: center;
  width: 100%;
  max-height: 60px;
  padding: 12px 16px;
  color: #fff;
  background-color: ${props => props.theme.colors.black};
    
  @media ${breakpoints.sm} {
    max-height: 80px;
    padding: 16px;
  }
    
  @media ${breakpoints.lg} {
    max-height: 128px;
    padding: 24px;
  }
`;
const H1 = styled.h1`
  font-family: 'NouvelRBold';
  font-size: 14px;
  text-transform: uppercase;
    
  @media ${breakpoints.sm} {
    font-size: 16px;
  }
`;
const Link = styled.a`
  display: inline-block;
  height: 36px;
  margin-right: 16px;
    
  @media ${breakpoints.sm} {
    height: 48px;
  }
    
  @media ${breakpoints.lg} {
    height: 80px;
    margin-right: 32px;
  }
`;
const Svg = styled.svg`
  display: block;
  height: 100%;
  fill: ${props => props.theme.colors.white};
`;

const Header = ({title}) => {
  return (
      <WrapperHeader>
        <Link href="/">
          <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66 86" className="FunnelHeader__logo">
            <path d="M52.3 43l-23 43H23L0 43 22.9 0h6.5L6.5 43l19.6 36.9L45.7 43 34.3 21.5l3.3-6.1L52.3 43zM42.5 0h-6.6L13.1 43l14.7 27.6 3.2-6.1L19.6 43 39.2 6l19.6 37-22.9 43h6.6l22.8-43L42.5 0z"></path>
          </Svg>
        </Link>
        <H1>
          {title}
        </H1>
      </WrapperHeader>
  );
};

Header.defaultProps = {
  title: ''
};

Header.propTypes = {
  title: PropTypes.string
};

export default Header;
