import styled from 'styled-components';
import { breakpoints } from "../../commons/breakpoints";
import { useTranslation } from "react-i18next";

const Wrapper = styled.footer`
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  margin-top: auto;
`;

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  padding: 8px;
  color: ${props => props.theme.colors.black};
  background-color: ${props => props.theme.colors.white};
  border-top: 1px solid #f2f2f2;
  
  @media ${breakpoints.sm} {
    flex-direction: row;
    padding: 8px 64px;
    align-items: center;
  }
`;

const List = styled.ul`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0;
  padding: 8px;
  
  @media ${breakpoints.sm} {
    flex-direction: row;
    padding: 0;
  }
`;

const ListElement = styled.li`
  position: relative;
  display: inline-block;
  padding: 4px 16px 4px 0;
  font-size: 12px;
  white-space: nowrap;
    
  @media ${breakpoints.sm} {
    &:after {
      position: absolute;
      top: 50%;
      right: 8px;
      transform: translateY(-50%);
      width: 1px;
      height: 12px;
      background-color: ${props => props.theme.colors.footerLinkColor};
      content: "";
    }
    
    &:last-child {
      &:after {
        display: none;
      }
    }
  }
`;

const Link = styled.a`
  position: relative;
  padding: 0;
  color: ${props => props.theme.colors.footerLinkColor};
  text-decoration: none;
    
  &:after {
    position: absolute;
     bottom: -2px;
     left: 0;
     width: 0;
     height: 1px;
     background-color: ${props => props.theme.colors.footerLinkColor};
     content: "";
     transition: all .2s;
  }
    
  &:hover {
   &:after {
    width: 100%;
  }
`;

const Copyright = styled.span`
  padding-left: 8px;
  font-size: 10px;
  font-family: 'NouvelRBold';
  text-transform: capitalize;
`;

const Footer = () => {
  const now = new Date();
  const year = now.getFullYear();
  const { t } = useTranslation();
  const linksMap = t('footerLinks', { returnObjects: true }).map(({href, anchor}, i) =>
      <ListElement key={i}>
        <Link href={href} title={anchor.toString()} target="_blank" rel="noopener">
          {anchor}
        </Link>
      </ListElement>
  );

  return (
      <Wrapper>
        <Nav>
          <List>
            {linksMap}
          </List>
          <Copyright>
            © Renault 2017 - {year}
          </Copyright>
        </Nav>
      </Wrapper>
  );
};

export default Footer;
