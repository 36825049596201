import React from 'react';
import styled from 'styled-components';
import { breakpoints } from "../../commons/breakpoints";
import Theme from '../../commons/theme';
import BenefitBox from './BenefitBox';
import LinkArrow from '../_shared/LinkArrow';
import Footer from '../../components/_shared/Footer';
import Header from '../../components/_shared/Header';
import {useTranslation} from "react-i18next";

const Section = styled.section`
  max-width: calc(${props => props.theme.sizes.contentWidth} - 32px);
  margin: 0 auto;
  padding: 16px;
  
  @media ${breakpoints.lg} {
    min-height: calc(100vh - 200px);
  }
`;

const Title = styled.h1`
  margin: 0;
  font-family: 'NouvelRBold';
  font-size: 22px;
  line-height: 32px;
  text-transform: uppercase;
`;

const BenefitBoxesContainer = styled.div`
  margin-bottom: 32px;
  
  @media ${breakpoints.sm} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 64px;
  }
`;

function BenefitsPage() {
  const { t } = useTranslation();
  const boxes = t('benefitsPageBoxes', { returnObjects: true }).map(({title, text}, i) =>
      <BenefitBox  title={title} text={text} number={i} key={i} />
  );

  return (
      <Theme>
        <Header />
        <Section className='benefit-boxes'>
          <Title className='benefit-boxes_title'>
            {t('benefitsPageTitle')}
            </Title>
          <BenefitBoxesContainer className='benefit-boxes_container'>
            {boxes}
          </BenefitBoxesContainer>
          <LinkArrow text={t('backButtonText')} destination="/" />
        </Section>
        <Footer />
      </Theme>
  );
}

export default BenefitsPage;
