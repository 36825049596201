import styled from 'styled-components';
import { breakpoints } from "../../commons/breakpoints";
import { useTranslation } from "react-i18next";
import ButtonPrimary from '../_shared/ButtonPrimary';
import {RaportDL} from '../_shared/DataLayer';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 16px 0;
  padding: 16px;
  background-color: hsla(0, 0%, 100%, .75);
  border-radius: 4px;
`;

const ButtonWrapper = styled.div`
  margin: 0 auto;
  
  @media ${breakpoints.sm} {
    width: 75%;
    text-align: center;
  }
`;

const Title = styled.span`
  position: relative;
  display: block;
  margin-bottom: 16px;
  padding-left: 16px;
  font-family: 'NouvelRBold';
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  
  &:before {
    position: absolute;
    left: 0;
    width: 4px;
    height: 100%;
    content: "";
    background-color: ${props => props.theme.colors.brandColor};
  }
  
  @media ${breakpoints.sm} {
    width: 100%;
    margin-bottom: 32px;
    padding-left: 0;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    
    &:before {
      display: none;
     }
  }
`;

const MainBox = () => {
  const { t } = useTranslation();

  const dl = () => {
    RaportDL('registration-year', 'step-1');
  };

  return (
      <Wrapper className="main-box">
        <ButtonWrapper className="main-box_wrapper">
          <Title className="main-box_title">
            {t('mainBoxTitle')}
          </Title>
          <ButtonPrimary className="main-box_button" text={t('mainButtonText')} destination="/form" callback={dl} />
        </ButtonWrapper>
      </Wrapper>
  );
};

export default MainBox;
