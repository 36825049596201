import React from 'react'
import {useTranslation} from "react-i18next";
import Header from "../_shared/Header";
import Footer from '../../components/_shared/Footer';
import Theme from "../../commons/theme";

export const FormLayout = ({ children }) => {
  const {t} = useTranslation();

  return (
    <Theme>
      <Header title={t('header')}/>
      {children}
      <Footer />
    </Theme>
  );
};

export default FormLayout
