import React, { useState } from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from "prop-types";
import DealerLocator from '../dealer-locator/DealerLocator';
import Title from './_shared/Title';
import FormButtons from "./_shared/FormButtons";
import Error from './Error';

const FormStep7 = ({step, setStep, data, setData}) => {
  const { t } = useTranslation();
  const [googlePlace, setGooglePlace] = useState();
  const [error, setError] = useState();

  const errorHandler = (text) => {
    setError(text);
  };

  const validHandler = () => {
    if (!data.dealerData.dealer) {
      setData({
        type: 'UPDATE_ERROR',
        payload: {
          error: true
        },
      });
    } else {
      setData({
        type: 'UPDATE_ERROR',
        payload: {
          error: false
        },
      });
      setData({
        type: 'UPDATE_NEXT_BUTTON',
        payload: {
          buttonStep7: true
        },
      });
      setStep(step + 1);
    }
  };

  return (
      <>
        {error &&
          <Error text={error} />
        }
        {!error &&
          <>
            <Title title={t('finalStepDealerTitle')}/>
            <DealerLocator center={{lat: 51.919438, lng: 19.145136}} zoom={6} dealers={data.dealerData.dealers} googlePlace={googlePlace} setGooglePlace={setGooglePlace} data={data} setData={setData} step={step} setStep={setStep} errorFunc={errorHandler}/>
            <FormButtons next={validHandler} nextActive={data.stepsNextButtons.buttonStep7} prev={() => setStep(step - 1)} error={data.error}/>
          </>
        }
      </>
  );
};

FormStep7.propTypes = {
  step: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
  dealerGroup: PropTypes.string
};

export default FormStep7;
