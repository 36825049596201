import React, {useEffect, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import { breakpoints } from "../../commons/breakpoints";
import styled from 'styled-components';
import Checkbox from './_shared/Checkbox';
import InputMaterial from './_shared/InputMaterial';
import NewCars from './_shared/NewCars';
import SelectButtons from './_shared/SelectButtons';
import Title from './_shared/Title';
import Loader from './_shared/Loader';
import FormButtons from "./_shared/FormButtons";
import Error from './Error';
import {RaportDL} from '../_shared/DataLayer';
import PropTypes from "prop-types";
import axios from "axios";

const Subtitle = styled.p`
  margin: -10px 0 32px;
  font-size: 12px;
  line-height: 17px;
  font-style: italic;
  
  @media ${breakpoints.md} {
    margin: -24px 0 32px;
    font-size: 14px;
    line-height: 20px;
  }
`;

const ValidInfo = styled.p`
  margin: 45px 16px 16px 0;
  font-style: italic;
  font-size: 10px;
  line-height: 15px;
`;

const Svg = styled.svg`
  position: relative;
  top: 3px;
  width: 15px;
  margin: 0 8px 0 0;
`;

const Fieldset = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -16px 16px;
`;

const FieldsetInfo = styled.div`
  margin-top: 8px;
  font-size: 10px;
  line-height: 14px;
`;

const DisclaimerContainer = styled.div`
  margin-top: 32px;
`;

const Paragraph = styled.div`
  margin-bottom: 17px;
  font-size: 8px;
  line-height: 11px;
  
  @media ${breakpoints.md} {
    font-size: 12px;
    line-height: 17px;
  }
`;

const HiddenCheckbox = styled.div`
  &.is-hidden {
    display: none;
  }
`;

const API_KEY = process.env.REACT_APP_API_KEY;
const API_URL = process.env.REACT_APP_API_URL;

const FormStep6 = ({step, setStep, data, setData}) => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const [newCars, setNewCars] = useState('');
  const [hiddenField, setHiddenField] = useState(true);
  const [error, setError] = useState(null);
  const newCarArray = [
    {label: t('formBuyCarLabelNew'), value: 'new'},
    {label: t('formBuyCarLabelUsed'), value: 'used'}
  ];
  const fieldErrors = {
    name: t('formAddressLabelNameError'),
    nameLength: t('formAddressLabelNameLengthError'),
    surname: t('formAddressLabelSurnameError'),
    surnameLength: t('formAddressLabelSurnameLengthError'),
    email: t('formAddressLabelEmailError'),
    phone: t('formAddressLabelPhoneError'),
    buyCar: t('formBuyCarError'),
    owu: t('formCheckboxOWUError'),
  };
  let smsFieldClass = 'is-hidden';

  useEffect(() => {
    setData({
      type: 'UPDATE_LOADER',
      payload: {
        loader: true,
      },
    });
    axios.get(`${API_URL}get-new-models`, {
      headers: {
        'apiKey': `${API_KEY}`
      }
    })
        .then(function(response) {
          if (response.data.Success) {
            setNewCars(response.data.Data.map(car => car.Value));
            setError(null);
          } else {
            setError(response.data.Message);
          }
          setData({
            type: 'UPDATE_LOADER',
            payload: {
              loader: false,
            },
          });
        })
        .catch(function(error) {
          console.log(error);
          setError(t('responseErrorDefault'));
          setData({
            type: 'UPDATE_LOADER',
            payload: {
              loader: false,
            },
          });
        });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const disclaimerParagraphs = t('formDisclaimerParagraphs', { returnObjects: true }).map(({paragraph}, i) =>
      <Trans key={i} components={{b: <b />, p: <Paragraph /> }}>{paragraph}</Trans>
  );

  const hiddenContentHandler = (e) => {
    setErrors({...errors, buyCar: ''});

    setData({
      type: 'UPDATE_USER_DATA',
      payload: {
        buyCar: e.target.value,
        buyCarError: false,
      },
    });

    if (e.target.value === 'new') {
      setData({
        type: 'UPDATE_USER_DATA',
        payload: {
          showNewCars: true,
        },
      });
      RaportDL('next-car', 'step-9', 'lead_vn');
    } else {
      setData({
        type: 'UPDATE_USER_DATA',
        payload: {
          showNewCars: false,
          newCar: '',
        },
      });
      RaportDL('next-car', 'step-9');
    }
  };

  const newCarHandler = (e) => {
    if (e.target.checked) {
      setData({
        type: 'UPDATE_USER_DATA',
        payload: {
          newCar: e.target.value
        },
      });
    } else {
      setData({
        type: 'UPDATE_USER_DATA',
        payload: {
          newCar: data.userData.newCar.filter(car => car !== e.target.value)
        },
      });
    }

    activeButton();
  };

  const owuHandler = (e) => {
    if (e.target.checked) {
      setErrors({...errors, owu: ''});
      setData({
        type: 'UPDATE_USER_DATA',
        payload: {
          owu: true
        },
      });
    } else {
      setErrors({...errors, owu: fieldErrors.owu});
      setData({
        type: 'UPDATE_USER_DATA',
        payload: {
          owu: false
        },
      });
    }

    activeButton();
  };

  const setCheckbox = (e) => {
    const name = e.target.name;

    if (e.target.checked) {
      setData({
        type: 'UPDATE_USER_DATA',
        payload: {
          [name]: true
        },
      });
    } else {
      setData({
        type: 'UPDATE_USER_DATA',
        payload: {
          [name]: false
        },
      });
    }
  };

  const setField = (e) => {
    setData({
      type: 'UPDATE_USER_DATA',
      payload: { [e[0]]: e[1] },
    });

    if (e[0] === 'phone') {
      if (e[1].length === 9) {
        setHiddenField(false);
      } else {
        setHiddenField(true);
      }
    }
  };

  const blurValidField = (e) => {
    if (e.name === 'phone') {
      if (!e.value || !/^\d{9}$/.test(e.value)) {
        setErrors({...errors, [e.name]: fieldErrors.[e.name]});
      } else {
        setErrors({...errors, [e.name]: ''});
      }
    } else if (e.name === 'email') {
      if (!e.value) {
        setErrors({...errors, [e.name]: fieldErrors.[e.name]});
      } else if (!/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/i.test(e.value)) {
        setErrors({...errors, [e.name]: fieldErrors.[e.name]});
      } else {
        setErrors({...errors, [e.name]: ''});
      }
    } else {
      if (!e.value) {
        setErrors({...errors, [e.name]: fieldErrors.[e.name]});
      } else {
        setErrors({...errors, [e.name]: ''});
      }
    }

    activeButton();
  };

  const validations = (values) => {
    let errors = {};

    if (!values.name) {
      errors.name = fieldErrors.name;
    }
    if (values.name.length > 40) {
      errors.name = fieldErrors.nameLength;
    }
    if (!values.surname) {
      errors.surname = fieldErrors.surname;
    }
    if (values.surname.length > 80) {
      errors.surname = fieldErrors.surnameLength;
    }
    if (!values.phone || !/^\d{9}$/.test(values.phone)) {
      errors.phone = fieldErrors.phone;
    }
    if (!values.email) {
      errors.email = fieldErrors.email;
    } else if (!/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/i.test(values.email)) {
      errors.email = fieldErrors.email;
    }
    if (!values.buyCar) {
      errors.buyCar = fieldErrors.buyCar;
    }
    if (values.buyCar === "new") {
      if (!values.newCar) {
        errors.newCar = fieldErrors.newCar;
      }
    }
    if (!values.owu) {
      errors.owu = fieldErrors.owu;
    }

    return errors;
  };

  const activeButton = () => {
    setTimeout(() => {
      if (data.userData.name !== '' && data.userData.surname !== '' && data.userData.email !== '' && data.userData.email !== '' && data.userData.buyCar !== '') {
        setData({
          type: 'UPDATE_NEXT_BUTTON',
          payload: {
            buttonStep6: true
          },
        });
      }
    }, 200);
  };

  const validHandler = () => {
    const vErrors = validations(data.userData);
    setErrors(vErrors);

    if (Object.keys(vErrors).length > 0) {
      setData({
        type: 'UPDATE_ERROR',
        payload: {
          error: true
        },
      });

      return;
    }
    setData({
      type: 'UPDATE_ERROR',
      payload: {
        error: false
      },
    });

    setData({
      type: 'UPDATE_NEXT_BUTTON',
      payload: {
        buttonStep6: true
      },
    });

    RaportDL('dealer', 'step-10');

    setStep(step + 1);
  };

  if (!hiddenField || data.userData.phone.length === 9) {
    smsFieldClass = '';
  }

  return (
      <>
        <Loader loaderClass={data.loader} />
        {error &&
          <Error text={error} />
        }
        {!error &&
          <>
            <Title title={t('formAddressTitle')}/>
            <Subtitle className='subtitle'>{t('formAddressSubtitle')}</Subtitle>
            <ValidInfo className='valid-info'>
              <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
                <path d="M28.5 12.6c-.7 0-1.1.7-1 1.3.63 12.05-14.4 18.01-22.2 8.8C1.7 18.4 1.5 12.4 4.6 8c3.1-4.4 8.9-6.5 14.1-4.8.4.1.9 0 1.2-.2.3-.2.5-.8.4-1.2-.1-.4-.4-.8-.8-.9C14-.9 7.8.8 3.9 5.1c-8.96 10.03-.99 25.84 12.4 24.6 8.2-.7 14.3-7.8 13.5-16-.1-.62-.67-1.16-1.3-1.1z"></path>
                <path d="M15 11.7c-.7 0-1.3.5-1.3 1.2v9c0 .6.6 1.2 1.2 1.2s1.3-.6 1.3-1.2v-9c0-.7-.6-1.2-1.3-1.2zm1.2-3.4c0-.7-.6-1.2-1.3-1.2s-1.2.5-1.2 1.2.6 1.2 1.2 1.2 1.3-.5 1.3-1.2z"></path>
              </Svg>
              {t('formAddressValidInfo')}
            </ValidInfo>
            <Fieldset>
              <InputMaterial name='name' label={t('formAddressLabelName')} value={data.userData.name} onChange={setField} onBlur={blurValidField} errorText={errors.name}/>
              <InputMaterial name='surname' label={t('formAddressLabelSurname')} value={data.userData.surname} onChange={setField} onBlur={blurValidField} errorText={errors.surname}/>
              <InputMaterial name='email' label={t('formAddressLabelEmail')} value={data.userData.email} onChange={setField} onBlur={blurValidField} errorText={errors.email}/>
              <InputMaterial name='phone' label={t('formAddressLabelPhone')} value={data.userData.phone} onChange={setField} onBlur={blurValidField} errorText={errors.phone}/>
            </Fieldset>
            <Title title={t('formBuyCarTitle')}/>
            <Subtitle className='subtitle'>{t('formBuyCarInfo')}</Subtitle>
            <SelectButtons blockButtons={newCarArray} callback={hiddenContentHandler} radioName='buyCarRadio' checkedField={data.userData.buyCar} errorText={errors.buyCar}/>
            {data.userData.showNewCars && newCars &&
              <NewCars carsArray={newCars} callback={newCarHandler} checkedField={data.userData.newCar}/>
            }
            <Checkbox name='owu' label={t('formCheckboxOWU')} labelLink={t('formCheckboxOWULink')} labelLinkAnchor={t('formCheckboxOWULinkAnchorText')} labelStar={true} value={data.userData.owu} callback={owuHandler} errorText={errors.owu}/>
            <div className='contact-checkboxes'>
              <FieldsetInfo>{t('formMarketingInformationAgreeText')}</FieldsetInfo>
              <Checkbox name='rEmail' label={t('formREmailTitle')} value={data.userData.rEmail} callback={setCheckbox}/>
              <Checkbox name='rPhone' label={t('formRPhoneTitle')} value={data.userData.rPhone} callback={setCheckbox}/>
              <HiddenCheckbox className={smsFieldClass}>
                <Checkbox name='rSms' label={t('formRSmsTitle')} value={data.userData.rSms} callback={setCheckbox}/>
              </HiddenCheckbox>
            </div>
            <DisclaimerContainer>
              {disclaimerParagraphs}
            </DisclaimerContainer>
            <FormButtons next={validHandler} nextActive={data.stepsNextButtons.buttonStep6} prev={() => setStep(step - 1)} error={data.error}/>
          </>
        }
      </>
  );
};

FormStep6.propTypes = {
  step: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
};

export default FormStep6;
