export const RaportDL = (name, step, action = 'lead_usedcar') => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    'formType' : 'trade_in_request', // Typ Formularza - stała wartość
    'formCategory' : 'lead_usedcar', // Typ Kategorii - stała wartość
    'formStepName' : name, // Nazwa kroku formularza - stała wartość
    'formStepNumber' : step, // Numer kroku formularza - stała wartość
    'event' : 'trackEvent', // Nazwa eventu - stała wartość
    'pageType' : 'tradein', // Typ strony - stała wartość
    'businessType' : 'used-car', // Typ biznesu - stała wartość
    'eventCategory' : 'Leadform Opening', // Wartość stała
    'eventAction' : action, // Wartość stała
    'eventLabel' : 'tradein_manual'  // Wartość Stała
  }) ;
};
