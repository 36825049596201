import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import { breakpoints } from "../../commons/breakpoints";
import Autocomplete from 'react-google-autocomplete';
import GoogleMapReact from 'google-map-react';
import PropTypes from 'prop-types';
import axios from "axios";
import ButtonSearchMap from './ButtonSearchMap';
import DealerBox from './DealerBox';
import Marker from './Marker';
import Loader from "../page-form/_shared/Loader";

const DealerLocatorWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 660px;
  min-height: 660px;
  overflow: hidden;
  background-color: ${props => props.theme.colors.black};
  border: 1px solid ${props => props.theme.colors.benefitBoxTitleBorder};
  
  &.is-group {
    height: 592px;
    min-height: 592px;
  }
`;

const MapWrapper = styled.div`
  position: realtive;
  display: flex;
  flex-direction: column;
  height: calc(100% - 68px);
  
  @media ${breakpoints.sm} {
    flex-direction: row;
  }
  
  .is-group & {
    height: 100%;
  }
`;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 32px);
  padding: 16px;
  background-color: ${props => props.theme.colors.black};
  
  @media ${breakpoints.sm} {
    width: calc(100% - 16px);
    padding: 8px;
  }
`;

const SearchInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 700px;
  
  @media ${breakpoints.sm} {
    flex-direction: row;
  }
`;

const OrSeparator = styled.span`
  margin: 8px 16px;
  color: ${props => props.theme.colors.white};
  font-family: 'NouvelRBold';
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase; 
`;

const SearchField = styled.div`
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  margin: 0;
  border: 0;
  border-radius: 2px;
`;

const Svg = styled.svg`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 100%;
  padding: 0 15px;
  background-color: ${props => props.theme.colors.brandColor};
  fill: ${props => props.theme.colors.black};
`;

const ClearFieldIcon = styled.svg`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 10px;
  padding: 10px;
  fill: ${props => props.theme.colors.black};
  
  &:hover {
    cursor: pointer;
  }
`;

const SearchInput = styled(Autocomplete)`
  display: block;
  width: 100%;
  margin: 0;
  padding: 16px 8px;
  border: 1px solid ${props => props.theme.colors.benefitBoxTitleBorder};
  border-radius: 2px;
  font-family: NouvelRRegular;
  font-size: 16px;
  line-height: 18px;
  
  @media ${breakpoints.sm} {
    max-width: 353px;
  }
  
  &:placeholder {
    font-family: 'NouvelR';
  }
  
  &:focus {
    outline: none;
  }
`;

const DealersContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  transition: width .5s ease;
  will-change: width;
  box-shadow: 0 2px 10px 0 rgb(0 0 0 / 20%);
  
  @media ${breakpoints.sm} {
    position: relative;
    width: 30%;
    min-width: 400px;
  }
`;

const DealersContainerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 47px;
  padding-left: 16px;
  overflow: hidden;
  background-color: ${props => props.theme.colors.brandColor};
  transition: height 1s ease;
  will-change: height;
`;

const DealersContainerIconsContainer = styled.div`
  height: 100%;
  border: 0;
  
  @media ${breakpoints.sm} {
    display: none;
  }
`;

const DealerContainerIconDealers = styled.button`
  display: inline-flex;
  align-items: center;
  position: relative;
  min-height: 40px;
  height: 100%;
  padding: 0 32px;
  border: 1px solid ${props => props.theme.colors.brandColor};
  background-color: ${props => props.theme.colors.brandColor};
`;

const DealerContainerIconMap = styled.button`
  display: inline-flex;
  align-items: center;
  position: relative;
  min-height: 40px;
  height: 100%;
  padding: 0 32px;
  border: 1px solid ${props => props.theme.colors.brandColor};
  background-color: ${props => props.theme.colors.brandColor};
`;

const SvgIcon = styled.svg`
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  width: 22px;
  fill: ${props => props.theme.colors.black};
`;

const DealersContainerCounter = styled.span`
  flex: 1;
  height: 35px;
  font-family: NouvelRBold;
  font-size: 14px;
  line-height: 35px;
  border-right: 1px solid ${props => props.theme.colors.black};
  
  @media ${breakpoints.sm} {
    border-right: none;
  }
`;

const DealersListWrapper = styled.div`
  position: absolute;
  top: 209px;
  width: 100%;
  height: 0;
  transition: height .5s ease;
  will-change: height;
  overflow: hidden;
  
  @media ${breakpoints.sm} {
    position: relative;
    top: 0;
    height: calc(100% - 47px);
  }
  
  &.is-open {
    height: calc(100% - 209px);
    
    .is-group & {
      height: calc(100% - 43px);
    }
  }
  
  .is-group & {
    top: 43px;
    
    @media ${breakpoints.sm} {
      top: 0;
    }
  }
`;

const DealersList = styled.ul`
  position: relative;
  flex: 1;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: ${props => props.theme.colors.white};
  overflow: auto;
  z-index: 1;
`;

const GOOGLE_MAPS_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const API_KEY = process.env.REACT_APP_API_KEY;
const API_URL = process.env.REACT_APP_API_URL;

const DealerLocator = ({center, zoom, dealers, googlePlace, setGooglePlace, data, setData, step, setStep, errorFunc}) => {
  const { t } = useTranslation();

  let dealerBoxes;
  let markers;

  const [mapCenter, setMapCenter] = useState(center);
  const [mapZoom, setMapZoom] = useState(zoom);
  const [activeDealer, setActiveDealer] = useState(0);
  const [mobileDealers, setMobileDealers] = useState(false);
  const [googlePlaceValue, setGooglePlaceValue] = useState('');

  const dealerGroup = sessionStorage.getItem('tradeIn-dealerGroup') || '';

  let newCar = (data.userData.buyCar === "new") ? "VN" : "VO";
  if (data.userData.buyCar === "no") {
    newCar = null;
  }

  useEffect(() => {
    resetMap();

    if (dealerGroup) {
      setData({
        type: 'UPDATE_LOADER',
        payload: {
          loader: true,
        },
      });

      axios.get(`${API_URL}get-dealers?searchModel.pageSize=25&searchModel.carType=${newCar}&searchModel.brand=Renault&searchModel.groupName=${dealerGroup}`, {
        headers: {
          'apiKey': `${API_KEY}`
        }
      })
          .then(function (response) {
            setData({
              type: 'UPDATE_DEALER_DATA',
              payload: {
                dealers: response.data
              },
            });
            setData({
              type: 'UPDATE_LOADER',
              payload: {
                loader: false,
              },
            });
            errorFunc(null);
          })
          .catch(function (error) {
            console.log(error);
            errorFunc(t('responseErrorDefault'));
            setData({
              type: 'UPDATE_LOADER',
              payload: {
                loader: false,
              },
            });
          });
    } else {
      if (googlePlace) {
        setData({
          type: 'UPDATE_LOADER',
          payload: {
            loader: true,
          },
        });

        axios.get(`${API_URL}get-dealers?searchModel.pageSize=25&searchModel.carType=${newCar}&searchModel.brand=Renault&searchModel.geolocation=${googlePlace}`, {
          headers: {
            'apiKey': `${API_KEY}`
          }
        })
            .then(function (response) {
              setData({
                type: 'UPDATE_DEALER_DATA',
                payload: {
                  dealers: response.data
                },
              });
              setData({
                type: 'UPDATE_LOADER',
                payload: {
                  loader: false,
                },
              });
              errorFunc(null);
              resetMapParam();
              scrollListToStart();
            })
            .catch(function (error) {
              console.log(error);
              errorFunc(t('responseErrorDefault'));
              setData({
                type: 'UPDATE_LOADER',
                payload: {
                  loader: false,
                },
              });
            });
      }
    }
  }, [googlePlace]); // eslint-disable-line react-hooks/exhaustive-deps

  const geolocationsHandler = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setGooglePlace(`${position.coords.latitude};${position.coords.longitude}`);
        setGooglePlaceValue('');
        resetMapParam();
        scrollListToStart();
      });
    }
  };

  const googlePlaceValueHandler = (e) => {
    setGooglePlaceValue(e.target.value);
  };

  const clearSearchHandler = () => {
    setGooglePlaceValue('');
    resetMap();
  };

  const resetMapParam = () => {
    setMapZoom(zoom);
    setMapCenter(center);
  };

  const resetMap = () => {
    setData({
      type: 'CLEAR_DEALER_DATA'
    });
    resetMapParam();
  };

  const setActiveBox = (id) => {
    const box = document.getElementById(id);
    const topPos = box.offsetTop;
    const list = document.getElementById('dealerList');
    list.scrollTop = topPos;
  };

  const scrollListToStart = () => {
    const list = document.getElementById('dealerList');
    if (list) {
      list.scrollTop = 0;
    }
  };

  const _onChildClick = (key, childProps) => {
    setActiveDealer(key);
    setMapZoom(14);
    setMapCenter({lat: childProps.lat, lng: childProps.lng});
    setActiveBox(key);
  };

  const _onChildMouseEnter = (key) => {
    setActiveDealer(key);
  };

  const hoverBoxHandler = (e) => {
    setActiveDealer(e.currentTarget.getAttribute('id'));
  };

  const showDealers = () => {
    setMobileDealers(true);
  };

  const hideDealers = () => {
    setMobileDealers(false);
  };

  if (data.dealerData.dealers && data.dealerData.dealers.Locations) {
    markers = data.dealerData.dealers.Locations.map(({Id, Lat, Lng}, i) => (
        <Marker key={Id}
                lat={Lat}
                lng={Lng}
                number={i+1}
                activeClass={(Number(activeDealer) === Number(Id)) ? 'is-active' : ''}
        />
    ));

    dealerBoxes = data.dealerData.dealers.Locations.map(({Id, Name, Street, PostalCode, City, PhoneNumber, Distance, Lat, Lng}, i) => (
        <DealerBox key={i}
                   i={i}
                   id={Id}
                   name={Name}
                   street={Street}
                   postalCode={PostalCode}
                   city={City}
                   phoneNumber={PhoneNumber}
                   distance={Distance}
                   activeDealer={activeDealer}
                   lat={Lat}
                   lng={Lng}
                   data={data}
                   setData={setData}
                   step={step}
                   setStep={setStep}
                   hoverFunc={hoverBoxHandler}
        />
    ));
  }

  return (
      <DealerLocatorWrapper className={dealerGroup ? 'is-group' : ''}>
        <Loader loaderClass={data.loader} />
        {!dealerGroup &&
          <SearchContainer>
            <SearchInnerContainer>
              <ButtonSearchMap onClickFunc={geolocationsHandler} />
              <OrSeparator>
                {t('dealerLocatorOr')}
              </OrSeparator>
              <SearchField>
                {!googlePlaceValue &&
                  <Svg viewBox="0 0 30 30">
                    <path
                        d="M29.251 27.717l-6.473-6.473c1.952-2.245 3.145-5.165 3.145-8.355 0-7.028-5.75-12.778-12.779-12.778C6.116.111.366 5.861.366 12.889s5.75 12.778 12.778 12.778c2.93 0 5.631-1.01 7.793-2.685l6.524 6.524c.256.256.511.383.895.383.383 0 .639-.128.895-.383a1.236 1.236 0 0 0 0-1.789zm-26.329-14.7c0-5.622 4.6-10.223 10.223-10.223s10.223 4.6 10.223 10.223-4.6 10.223-10.223 10.223c-5.623-.001-10.223-4.601-10.223-10.223z"></path>
                  </Svg>
                }
                {googlePlaceValue &&
                  <ClearFieldIcon viewBox="0 0 30 30" onClick={clearSearchHandler}>
                    <path d="M29.999 2.512L27.5.012 14.999 12.504 2.498.012l-2.497 2.5L12.5 15.001.001 27.488l2.497 2.5 12.501-12.49L27.5 29.988l2.497-2.5-12.499-12.487z"></path>
                  </ClearFieldIcon>
                }
                <SearchInput
                    type='text'
                    placeholder={t('dealerLocatorInputPlaceholder')}
                    value={googlePlaceValue}
                    onChange={googlePlaceValueHandler}
                    apiKey={GOOGLE_MAPS_KEY}
                    onPlaceSelected={(place) => {
                      setGooglePlace(`${place.geometry.location.lat()};${place.geometry.location.lng()}`);
                    }} />
              </SearchField>
            </SearchInnerContainer>
          </SearchContainer>
        }
        <MapWrapper>
          {data.dealerData.dealers && data.dealerData.dealers.Locations &&
          <DealersContainer>
            <DealersContainerHeader>
              <DealersContainerCounter>
                {data.dealerData.dealers.Locations.length} {t('dealerLocatorCountPostfix')}
              </DealersContainerCounter>
              <DealersContainerIconsContainer>
                {!mobileDealers &&
                  <DealerContainerIconDealers onClick={showDealers}>
                    <SvgIcon viewBox="0 0 30 30"><path d="M28.297 5.008H9.716c-.769 0-1.393-.609-1.393-1.359S8.947 2.29 9.716 2.29h18.58c.771 0 1.394.609 1.394 1.359s-.624 1.359-1.393 1.359zm0 10.684H9.716c-.769 0-1.393-.609-1.393-1.359s.624-1.359 1.393-1.359h18.58c.771 0 1.394.609 1.394 1.359s-.624 1.359-1.393 1.359zm0 12.018H9.716c-.769 0-1.393-.609-1.393-1.359s.624-1.359 1.393-1.359h18.58c.771 0 1.394.609 1.394 1.359-.001.75-.624 1.359-1.393 1.359zM3.402 5.008H1.153c-.465 0-.843-.609-.843-1.359s.378-1.359.843-1.359h2.249c.466 0 .843.609.843 1.359s-.377 1.359-.843 1.359zm0 10.684H1.153c-.465 0-.843-.609-.843-1.359s.378-1.359.843-1.359h2.249c.466 0 .843.609.843 1.359.001.75-.377 1.359-.843 1.359zm0 12.018H1.153c-.465 0-.843-.609-.843-1.359s.378-1.359.843-1.359h2.249c.466 0 .843.609.843 1.359s-.377 1.359-.843 1.359z"></path></SvgIcon>
                  </DealerContainerIconDealers>
                }
                {mobileDealers &&
                  <DealerContainerIconMap onClick={hideDealers}>
                    <SvgIcon viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg"><path d="M27.55 22.61l-6.641 4.045V8.383l6.641-4.046V22.61zM10.946 4.22l8.302 4.213v18.34l-8.302-4.214V4.219zM2.644 8.382l6.642-4.046V22.61l-6.642 4.046V8.383zm26.152-6.27a.822.822 0 0 0-.83 0L20.077 6.95 10.48 2.096a.772.772 0 0 0-.249 0l-.116-.085a.802.802 0 0 0-.3.085H9.7L1.399 7.152A.844.844 0 0 0 1 7.877v20.227a.836.836 0 0 0 .825.848.82.82 0 0 0 .437-.123l7.854-4.787 9.547 4.854a.815.815 0 0 0 .796 0l8.302-5.056a.842.842 0 0 0 .4-.725V2.855a.844.844 0 0 0-.365-.742z" fillRule="evenodd"></path></SvgIcon>
                  </DealerContainerIconMap>
                }
              </DealersContainerIconsContainer>
            </DealersContainerHeader>
            <DealersListWrapper className={(mobileDealers) ? 'is-open' : ''}>
              <DealersList id="dealerList">
                {dealerBoxes}
              </DealersList>
            </DealersListWrapper>
          </DealersContainer>
          }
          <GoogleMapReact
              bootstrapURLKeys={{key: GOOGLE_MAPS_KEY}}
              defaultCenter={center}
              defaultZoom={zoom}
              center={mapCenter}
              zoom={mapZoom}
              onChildClick={_onChildClick}
              onChildMouseEnter={_onChildMouseEnter}
          >
            {markers}
          </GoogleMapReact>
        </MapWrapper>
      </DealerLocatorWrapper>
  );
};

DealerLocator.defaultProps = {
  activeDealer: {},
  dealerList: [],
  center: {lat: 51.919438, lng: 19.145136},
  zoom: 5
};

DealerLocator.propTypes = {
  activeDealer: PropTypes.object,
  dealerList: PropTypes.array,
  center: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number
  }),
  zoom: PropTypes.number
};

export default DealerLocator;
