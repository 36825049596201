import React from 'react';
import styled from 'styled-components';
import { breakpoints } from "../../commons/breakpoints";

const Box = styled.div`
  padding: 15px;
  background-color: ${props => props.theme.colors.darkWhite};
  border-bottom: 1px solid ${props => props.theme.colors.footerLinkColor};
  
  @media ${breakpoints.sm} {
    width: calc(29% + 4px);
    margin-bottom: 16px;
    padding: 20px;
    border-bottom: none;
    box-shadow: 0 0 5px 0 ${props => props.theme.colors.darkWhite};
  }
`;

const Title = styled.p`
  position: relative;
  margin: 0 0 13px 45px;
  padding-bottom: 13px;
  font-family: 'NouvelRBold';
  font-size: 18px;
  line-height: 26px;
  text-transform: uppercase;
  
  @media ${breakpoints.sm} {
    margin: 0 0 13px 40px;
    border-bottom: 1px solid ${props => props.theme.colors.benefitBoxTitleBorder};
  }
`;

const Text = styled.p`
  margin: 0;
  padding-left: 45px;
  font-size: 14px;
  line-height: 20px;
   
  @media ${breakpoints.sm} {
    padding-left: 40px;
  }
`;

const Number = styled.span`
  position: absolute;
  left: -45px;
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-right: 15px;
  font-family: 'NouvelRBold';
  font-size: 16px;
  text-align: center;
  background-color: ${props => props.theme.colors.brandColor};
  border-radius: 50%;
  
  @media ${breakpoints.sm} {
    left: -40px;
    margin-right: 10px
    font-size: 18px;
  }
`;

function BenefitsBox({title, text, number}) {
  return (
      <Box className='benefit-box'>
        <Title className='benefit-box_title'>
          <Number className='benefit-box_number'>
            {number + 1}
          </Number>
          {title}
        </Title>
        <Text className='benefit-box_text'>
          {text}
        </Text>
      </Box>
  );
}

export default BenefitsBox;
