import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import axios from 'axios';
import Title from './_shared/Title';
import Buttons from './_shared/Buttons';
import Loader from "./_shared/Loader";
import FormButtons from "./_shared/FormButtons";
import Error from './Error';
import {RaportDL} from '../_shared/DataLayer';
import PropTypes from "prop-types";

const API_KEY = process.env.REACT_APP_API_KEY;
const API_URL = process.env.REACT_APP_API_URL;

const FormStep3 = ({step, setStep, data, setData}) => {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const fuelsArray = [];

  useEffect(() => {
    setData({
      type: 'UPDATE_LOADER',
      payload: {
        loader: true,
      },
    });

    axios.get(`${API_URL}get-fuels?makeId=${data.carData.brand.value}&modelId=${data.carData.model}&date=${data.carData.year}.${data.carData.monthIndex}`, {
      headers: {
        'apiKey': `${API_KEY}`
      }
    })
        .then(function(response) {
          if (response.data.Success) {
            setData({
              type: 'UPDATE_CAR_DATA',
              payload: {
                fuels: response.data.Data
              },
            });
            setError(null);
          } else {
            setError(response.data.Message);
          }
          setData({
            type: 'UPDATE_LOADER',
            payload: {
              loader: false,
            },
          });
        })
        .catch(function(error) {
          console.log(error);
          setError(t('responseErrorDefault'));
          setData({
            type: 'UPDATE_LOADER',
            payload: {
              loader: false,
            },
          });
        });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (data.carData.fuels) {
    data.carData.fuels.forEach((fuel) => {
      fuelsArray.push({label: fuel.Value, value: fuel.Key});
    });
  }

  const hiddenContentHandler = (e) => {
    setData({
      type: 'UPDATE_CAR_DATA',
      payload: {
        fuel: e.target.value
      },
    });

    setData({
      type: 'CHANGE_FUEL'
    });

    setData({
      type: 'UPDATE_NEXT_BUTTON',
      payload: {
        buttonStep3: true
      },
    });

    RaportDL('version', 'step-6');

    setStep(step + 1);
  };

  const validHandler = () => {
    if (!data.carData.fuel) {
      setData({
        type: 'UPDATE_ERROR',
        payload: {
          error: true
        },
      });
    } else {
      setData({
        type: 'UPDATE_ERROR',
        payload: {
          error: false
        },
      });
      setStep(step + 1);
    }
  };

  return (
      <>
        <Loader loaderClass={data.loader} />
        {error &&
        <Error text={error} />
        }
        {!error &&
          <>
            <Title title={t('formTitleFuel')}/>
            {data.carData.fuels &&
              <Buttons blockButtons={fuelsArray} callback={hiddenContentHandler} radioName='fuelRadio' checkedField={data.carData.fuel}/>
            }
            <FormButtons next={validHandler} nextActive={data.stepsNextButtons.buttonStep3} prev={() => setStep(step - 1)} error={data.error}/>
          </>
        }
      </>
  );
};

FormStep3.propTypes = {
  step: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
};

export default FormStep3;
