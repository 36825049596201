import React from 'react';
import styled, { keyframes } from 'styled-components';

const loading = keyframes`
  0%{width:0}50%{width:100%}to{width:0}
`;

const LoaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  display: none;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, .4);
  
  &.nontransparent {
    background-color: rgba(255, 255, 255, 1);
  }
  
  &.is-active {
    display: block;
    transition: height 0s ease 0s, background .25s ease-in-out 0s;
  }
`;

const LoaderBelt = styled.div`
  position: relative;
  height: 2px;
`;

const LoaderBar = styled.div`
  position: absolute;
  left: 50%;
  width: 0;
  display: inline-block;
  height: 100%;
  content: "";
  transform: translateX(-50%);
  text-align: center;
  background-color: ${props => props.theme.colors.brandColor};
  animation: 1s linear 0s infinite normal none running ${loading};
`;

const Svg = styled.svg`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  width: 50px;
  height: 100%;
  fill: ${props => props.theme.colors.black};
`;

const Loader = ({loaderClass, opacity}) => {
  let lClass = 'loader';

  if (loaderClass) {
    lClass += ' is-active';
  }

  if (opacity) {
    lClass += ' nontransparent';
  }

  return (
      <LoaderWrapper className={lClass}>
        <LoaderBelt className='loader-belt'>
            <LoaderBar className='loader-bar' />
        </LoaderBelt>
        {opacity &&
          <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66 86">
            <path d="M52.3 43l-23 43H23L0 43 22.9 0h6.5L6.5 43l19.6 36.9L45.7 43 34.3 21.5l3.3-6.1L52.3 43zM42.5 0h-6.6L13.1 43l14.7 27.6 3.2-6.1L19.6 43 39.2 6l19.6 37-22.9 43h6.6l22.8-43L42.5 0z"></path>
          </Svg>
        }
      </LoaderWrapper>
  );
};

export default Loader;
