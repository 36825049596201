import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import { breakpoints } from "../../commons/breakpoints";
import axios from 'axios';
import Title from './_shared/Title';
import CustomSelect from './_shared/CustomSelect';
import SelectButtons from './_shared/SelectButtons';
import Loader from './_shared/Loader';
import FormButtons from "./_shared/FormButtons";
import Error from './Error';
import {RaportDL} from '../_shared/DataLayer';
import PropTypes from "prop-types";

const ButtonsContainer = styled.div`
  @media ${breakpoints.sm} {
    margin-top: 32px;
    padding: 16px 16px 24px;
    background-color: ${props => props.theme.colors.darkWhite};
  }
`;

const API_KEY = process.env.REACT_APP_API_KEY;
const API_URL = process.env.REACT_APP_API_URL;

const FormStep2 = ({step, setStep, data, setData}) => {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const optionsBrand = [];
  const modelsArray = [];

  useEffect(() => {
    if (data.carData.year && data.carData.monthIndex) {
      setData({
        type: 'UPDATE_LOADER',
        payload: {
          loader: true,
        },
      });
      axios.get(`${API_URL}get-makes?date=${data.carData.year}.${data.carData.monthIndex}`, {
        headers: {
          'apiKey': `${API_KEY}`
        }
      })
          .then(function (response) {
            if (response.data.Success) {
              setData({
                type: 'UPDATE_CAR_DATA',
                payload: {
                  brands: response.data.Data
                },
              });
              setError(null);
            } else {
              setError(response.data.Message);
            }
            setData({
              type: 'UPDATE_LOADER',
              payload: {
                loader: false,
              },
            });
          })
          .catch(function (error) {
            console.log(error);
            setError(t('responseErrorDefault'));
            setData({
              type: 'UPDATE_LOADER',
              payload: {
                loader: false,
              },
            });
          });
    }
  }, [data.carData.year, data.carData.monthIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (data.carData.brand && data.carData.year && data.carData.monthIndex) {
      setData({
        type: 'UPDATE_LOADER',
        payload: {
          loader: true,
        },
      });
      axios.get(`${API_URL}get-models?makeId=${data.carData.brand.value}&date=${data.carData.year}.${data.carData.monthIndex}`, {
        headers: {
          'apiKey': `${API_KEY}`
        }
      })
          .then(function(response) {
            if (response.data.Success) {
              setData({
                type: 'UPDATE_CAR_DATA',
                payload: {
                  models: response.data.Data
                },
              });
              setError(null);
            } else {
              setError(response.data.Message);
            }
            setData({
              type: 'UPDATE_LOADER',
              payload: {
                loader: false,
              },
            });
          })
          .catch(function(error) {
            console.log(error);
            setError(t('responseErrorDefault'));
            setData({
              type: 'UPDATE_LOADER',
              payload: {
                loader: false,
              },
            });
          });
    }
  }, [data.carData.brand, data.carData.monthIndex, data.carData.year]); // eslint-disable-line react-hooks/exhaustive-deps

  if (data.carData.brands) {
    data.carData.brands.forEach((brand) => {
      optionsBrand.push({label: brand.Value, value: brand.Key});
    });
  }

  const chooseBrand = (e) => {
    setData({
      type: 'UPDATE_CAR_DATA',
      payload: {
        brand: e
      },
    });

    setData({
      type: 'CHANGE_BRAND'
    });

    RaportDL('model', 'step-4');
  };

  if (data.carData.models) {
    data.carData.models.forEach((model) => {
      modelsArray.push({label: model.Value, value: model.Key});
    });
  }

  const hiddenContentHandler = (e) => {
    setData({
      type: 'UPDATE_CAR_DATA',
      payload: {
        model: e.target.value
      },
    });

    setData({
      type: 'CHANGE_MODEL'
    });

    setData({
      type: 'UPDATE_NEXT_BUTTON',
      payload: {
        buttonStep2: true
      },
    });

    RaportDL('fuel', 'step-5');

    setStep(step + 1);
  };

  const hiddenContentHandlerSelect = (e) => {
    setData({
      type: 'UPDATE_CAR_DATA',
      payload: {
        model: e.value,
        modelName: e.label
      },
    });

    setData({
      type: 'CHANGE_MODEL'
    });

    setData({
      type: 'UPDATE_NEXT_BUTTON',
      payload: {
        buttonStep2: true
      },
    });

    setStep(step + 1);
  };

  const validHandler = () => {
    if (!data.carData.brand || !data.carData.model) {
      setData({
        type: 'UPDATE_ERROR',
        payload: {
          error: true
        },
      });
    } else {
      setData({
        type: 'UPDATE_ERROR',
        payload: {
          error: false
        },
      });
      setStep(step + 1);
    }
  };

  return (
      <>
        <Loader loaderClass={data.loader} />
        {error &&
          <Error text={error} />
        }
        {!error &&
          <>
            <Title title={t('formTitleCarMark')} />
            <CustomSelect selectOptions={optionsBrand} callback={chooseBrand} value={data.carData.brand}/>
            {data.carData.models &&
              <ButtonsContainer>
                <SelectButtons blockButtons={modelsArray} selectOptions={modelsArray} callback={hiddenContentHandler} callbackSelect={hiddenContentHandlerSelect} radioName='modelRadio' checkedField={data.carData.model} selectCheckedField={data.carData.model ? {"label": data.carData.modelName, "value": data.carData.model} : {"label": t('selectPlaceholder'), "value": ""}} />
              </ButtonsContainer>
            }
            <FormButtons next={validHandler} nextActive={data.stepsNextButtons.buttonStep2} prev={() => setStep(step - 1)} error={data.error} />
          </>
        }
      </>
  );
};

FormStep2.propTypes = {
  step: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
};

export default FormStep2;
