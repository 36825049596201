import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Theme from './commons/theme';
import BenefitsPage from './components/page-benefits/BenefitsPage';
import FormPage from './components/page-form/Form';
import MainPage from './components/main-page/MainPage';
import './fonts/NouvelR-Regular.woff2';

const url = new URL(window.location.href);
const dealer = url.searchParams.get('dealer');
const campaign = url.searchParams.get('campaign');
const CAMPAIGN = url.searchParams.get('CAMPAIGN');
const origin = url.searchParams.get('origin');
const ORIGIN = url.searchParams.get('ORIGIN');

if (dealer) {
  sessionStorage.setItem('tradeIn-dealerGroup', dealer);
}

if (campaign) {
  sessionStorage.setItem('tradeIn-campaign', campaign);
}

if (CAMPAIGN) {
  sessionStorage.setItem('tradeIn-campaign', CAMPAIGN);
}

if (origin) {
  sessionStorage.setItem('tradeIn-origin', origin);
}

if (ORIGIN) {
  sessionStorage.setItem('tradeIn-origin', ORIGIN);
}

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/benefits">
          <BenefitsPage />
        </Route>
        <Route path="/form">
          <FormPage />
        </Route>
        <Route path="/">
          <Theme>
            <div className="App">
              <MainPage />
            </div>
          </Theme>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
