import React from 'react'
import FormStep1 from './FormStep1'
import FormStep2 from './FormStep2'
import FormStep3 from './FormStep3'
import FormStep4 from './FormStep4'
import FormStep5 from './FormStep5'
import FormStep6 from './FormStep6'
import FormStep7 from './FormStep7'
import FormStep8 from './FormStep8'


const FormSteps = (step, setStep, data, setData) => [
  {
    activeStep: '1',
    accomplish: '1/6',
    accomplishStep: '1',
    content: (
        <FormStep1
            setStep={setStep}
            step={step}
            data={data}
            setData={setData}
        />
    ),
  },
  {
    activeStep: '1',
    accomplish: '2/6',
    accomplishStep: '1',
    content: (
        <FormStep2
            setStep={setStep}
            step={step}
            data={data}
            setData={setData}
        />
    ),
  },
  {
    activeStep: '1',
    accomplish: '3/6',
    accomplishStep: '1',
    content: (
        <FormStep3
            setStep={setStep}
            step={step}
            data={data}
            setData={setData}
        />
    ),
  },
  {
    activeStep: '1',
    accomplish: '4/6',
    accomplishStep: '1',
    content: (
        <FormStep4
            setStep={setStep}
            step={step}
            data={data}
            setData={setData}
        />
    ),
  },
  {
    activeStep: '1',
    accomplish: '5/6',
    accomplishStep: '1',
    content: (
        <FormStep5
            setStep={setStep}
            step={step}
            data={data}
            setData={setData}
        />
    ),
  },
  {
    activeStep: '2',
    accomplish: '',
    accomplishStep: '2',
    content: (
        <FormStep6
            setStep={setStep}
            step={step}
            data={data}
            setData={setData}
        />
    ),
  },
  {
    activeStep: '2',
    accomplish: '1/2',
    accomplishStep: '2',
    content: (
        <FormStep7
            setStep={setStep}
            step={step}
            data={data}
            setData={setData}
        />
    ),
  },
  {
    activeStep: '3',
    accomplish: '',
    accomplishStep: '3',
    content: (
        <FormStep8
            setStep={setStep}
            step={step}
            data={data}
            setData={setData}
        />
    ),
  },
];

export default FormSteps;
