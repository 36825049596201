import React from 'react';
import {useTranslation} from 'react-i18next';
import Input from './_shared/Input';
import Title from './_shared/Title';
import FormButtons from "./_shared/FormButtons";
import {RaportDL} from '../_shared/DataLayer';
import PropTypes from "prop-types";

const FormStep5 = ({step, setStep, data, setData}) => {
  const { t } = useTranslation();

  const setMileage = (e) => {
    if (/^[0-9]*$/.test(e.target.value)) {
      setData({
        type: 'UPDATE_CAR_DATA',
        payload: {
          mileage: e.target.value
        },
      });

      setData({
        type: 'UPDATE_NEXT_BUTTON',
        payload: {
          buttonStep5: true
        },
      });
    }
  };

  const validHandler = () => {
    if (!data.carData.mileage) {
      setData({
        type: 'UPDATE_ERROR',
        payload: {
          error: true
        },
      });
    } else {
      setData({
        type: 'UPDATE_ERROR',
        payload: {
          error: false
        },
      });
      RaportDL('contact', 'step-8');
      setStep(step + 1);
    }
  };

  return (
      <>
        <Title title={t('formMillageTitle')} />
        <Input name='mileage' value={data.carData.mileage} postfix='km' onChange={setMileage} />
        <FormButtons next={validHandler} nextActive={data.stepsNextButtons.buttonStep5} prev={() => setStep(step - 1)} error={data.error} />
      </>
  );
};

FormStep5.propTypes = {
  step: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
};

export default FormStep5;
