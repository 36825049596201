import React from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../commons/breakpoints';
import { useTranslation } from 'react-i18next';
import LinkButton from '../_shared/LinkButton';

const List = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  margin: 16px 0;
  padding: 16px;
  background-color: hsla(0, 0%, 100%, .75);
  border-radius: 4px;
  
  @media ${breakpoints.sm} {
    flex-wrap: wrap;
  }
`;

const ListItem = styled.li`
  display: flex;
  flex-grow: 1;
  position: relative;
  font-size: 11px;
  line-height: 16px;
  padding: 0 0 0 32px;
  
  @media ${breakpoints.sm} {
    width: 100%;
    margin: 16px 8px;
    padding-left: 64px;
    font-size: 16px;
    line-height: 20px;
  }
  
  &:after {
    position: absolute;
    left: 0;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    content: "";
    background-color: ${props => props.theme.colors.black};
    
    @media ${breakpoints.sm} {
      width: 30px;
      height: 30px;
    }
  }
`;

const Svg = styled.svg`
  position: absolute;
  left: 1px;
  z-index: 1;
  display: block;
  width: 15px;
  fill: ${props => props.theme.colors.brandColor};
  
  @media ${breakpoints.sm} {
    top: 4px;
    left: 7px;
    width: 25px;
  }
`;

const BenefitTitle = styled.p`
  margin: 0 0 4px;
  font-family: 'NouvelRBold';
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
`;

const BenefitDesc = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 20px;
`;

const Benefits = () => {
  const { t } = useTranslation();
  const benefitsMap = t('benefits', { returnObjects: true }).map(({title, desc}, i) =>
      <ListItem className="benefit-item" key={i}>
        <div>
          <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" className="svg-icon">
            <path d="M29 9.182L23.91 4 12.03 16.092l-5.94-6.046L1 15.228l11.03 11.228z"></path>
          </Svg>
          <BenefitTitle className="benefit_title">{title}</BenefitTitle>
          <BenefitDesc className="benefit_desc">{desc}</BenefitDesc>
        </div>
      </ListItem>
  );

  return (
    <>
      <List className="benefits">
        {benefitsMap}
      </List>
      <LinkButton text={t('moreDetailsLinkText')} destination='/benefits' />
    </>
  );
};

export default Benefits;
