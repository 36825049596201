import React from 'react';
import { breakpoints } from "../../../commons/breakpoints";
import styled from 'styled-components';
import PropTypes from "prop-types";

const Error = styled.span`
  position: absolute; 
  top: 68px;
  display: none;
  margin-top: 2px;
  color: ${props => props.theme.colors.errorColor};
  font-size: 13px;
  line-height: 19px;
`;

const Label = styled.label`
  flex: 0 0 calc(100% - 32px);
  position: relative;
  display: inline-block;
  margin: 8px 0 16px;
  padding: 0 16px;
  font-size: 16px;
  line-height: 23px;
  
  @media ${breakpoints.sm} {
    flex: 0 0 calc(50% - 32px);
  }
  
  &.is-filled {
    span {
      font-size: 12.8px !important;
      line-height: 18px !important;
    }
  }
  
  &.is-invalid {
    ${Error} {
      display: block;
    }
  }
`;

const Input = styled.input`
  display: block;
  box-sizing: border-box;
  margin: 0;
  border: none;
  border-top: solid 27px transparent;
  border-bottom: solid 1px ${props => props.theme.colors.benefitBoxTitleBorder};
  padding: 8px 0;
  width: 100%;
  height: inherit;
  color: ${props => props.theme.colors.footerLinkColor};
  background-color: transparent;
  box-shadow: none; /* Firefox */
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  transition: border-bottom 0.2s, background-color 0.2s;
  
  &:hover {
    border-bottom-color: ${props => props.theme.colors.footerLinkColor};
    
    .is-invalid & {
      border-bottom-color: ${props => props.theme.colors.errorColor};
    }
  }
  
  &:not(:focus) + span {
    font-size: inherit;
    line-height: 56px;
  }
  
  &:focus {
    outline: none;
  }
 
  
  &:focus + span::=before {
    opacity: 0.12;
  }
  
  &:focus + span:after {
    transform: scale(1);
  }
  
  .is-invalid & {
    border-bottom-color: ${props => props.theme.colors.errorColor};
  }
  
  & + span {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    box-sizing: border-box;
    margin: 10px 0 0 16px;
    color: ${props => props.theme.colors.materialInputColor};
    font-size: 80%;
    line-height: 18px;
    pointer-events: none;
    transition: color 0.2s, font-size 0.2s, line-height 0.2s;
    
    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      display: block;
      width: calc(100% - 16px);
      height: 1px;
      background-color: ${props => props.theme.colors.footerLinkColor};
      transform-origin: bottom center;
      transform: scaleX(0);
      transition: transform 0.2s;
    }
  }
`;

const InputText = styled.span`

`;

const InputMaterial = ({name, value, type, label, onChange, onBlur, errorText}) => {
  let inputClass = 'input-material_label';

  if (value) inputClass += ' is-filled';
  if (errorText && errorText !== '') inputClass += ' is-invalid';

  return (
      <Label className={inputClass}>
        <Input className='input-material_input' name={name} value={value} type={type} onChange={e => onChange([e.target.name, e.target.value])} onBlur={e => onBlur(e.target)} />
        <InputText>{label}</InputText>
        {errorText &&
          <Error>{errorText}</Error>
        }
      </Label>
  );
};

InputMaterial.defaultProps = {
  value: '',
  type: 'text',
  label: '',
  errorText: '',
  error: ''
};

Input.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  errorText: PropTypes.string,
  error: PropTypes.object
};

export default InputMaterial;
