import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { breakpoints } from "../../../commons/breakpoints";
import Theme from '../../../commons/theme';
import Select, { components } from 'react-select';

const Error = styled.div`
  position: absolute; 
  top: 120px;
  display: none;
  margin-top: 2px;
  color: ${props => props.theme.colors.errorColor};
  font-size: 10px;
  line-height: 14px;
  
  @media ${breakpoints.sm} {
    top: 48px;
  }
`;

const BlockSelectButtons = styled.div`
  position: relative;
  display: none;
  width: 100%;
  margin-bottom: -16px;
  
  @media ${breakpoints.sm} {
    display: flex;
    flex-wrap: wrap;
  }
  
  &.show-mobile {
    display: flex;
    flex-direction: column;
    
    @media ${breakpoints.sm} {
      flex-direction: row;
    }
  }
  
  &.is-invalid {
    ${Error} {
      display: block;
    }
  }
`;

const ButtonDiv = styled.div`
  margin: 0 8px 8px;
  padding-bottom: 8px;
  width: calc(25% - 16px);
  
  .show-mobile & {
    width: calc(100% - 16px);
    padding-bottom: 0;
    
    @media ${breakpoints.sm} {
      width: calc(25% - 16px);
      padding-bottom: 8px;
    }
  }
`;

const ButtonLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-bottom: 16px;
  padding: 4px 0;
  color: ${props => props.theme.colors.footerLinkColor};
  font-family: 'NouvelRRegular';
  font-size: 14px;
  line-height: 14px;
  background-color: ${props => props.theme.colors.darkWhite};
  border: none;
  
  &:hover {
    cursor: pointer;
    font-family: 'NouvelRBold';
    box-shadow: ${props => props.theme.colors.brandColor} 0px 0px 0px 2px inset;
  }
  
  .show-mobile & {
    margin-bottom: 0;
    padding: 10px 0;
    
    @media ${breakpoints.sm} {
      margin-bottom: 16px;
      padding: 4px 0;
    }
  }
  
  .is-invalid & {
    color: ${props => props.theme.colors.errorColor};
  }
`;

const ButtonInput = styled.input`
  position: absolute;
  left: -9999px;
  
  &:checked + ${ButtonLabel} {
    font-family: 'NouvelRBold';
    box-shadow: ${props => props.theme.colors.brandColor} 0px 0px 0px 2px inset;
  }
`;

const Svg = styled.svg`
  width: 18px;
  height: 18px;
  margin-left: 9px;
  fill: ${props => props.theme.colors.brandColor};
`;

const SelectComponent = styled(Select)`
  @media ${breakpoints.sm} {
    display: none;
  }
`;

const selectStyle = {
  control: styles => ({
    ...styles,
    marginBottom: 16,
    boxShadow: 'none',
    borderColor: '#efdf00',
    borderRadius: 0,
    color: '#000',
    fontFamily: 'NouvelRBold',
    fontSize: '16px',
    lineHeight: '18px',

    ':hover': {
      borderColor: '#efdf00'
    }
  }),
  placeholder: styles => ({
    ...styles,
    color: '#000',
    fontFamily: 'NouvelRBold',
    fontSize: '16px',
    lineHeight: '18px',
  }),
  singleValue: styles => ({
    ...styles,
    color: '#000',
    padding: 0
  }),
  valueContainer: styles => ({
    ...styles,
    padding: '11px 30px'
  }),
  indicatorSeparator: styles => ({
    ...styles,
    margin: 0,
    backgroundColor: '#efdf00'
  }),
  indicatorsContainer: styles => ({
    ...styles,
    alignItems: 'center',
    width: 52
  }),
  option: (styles, {data, isSelected} )=> ({
    ...styles,
    border: '1px solid #fff',
    backgroundColor:
      isSelected ? '#ade1f5' : null
  })
};

const DropdownIndicator = (props: ElementConfig<typeof components.DropdownIndicator>) => {
  return (
      <components.DropdownIndicator {...props}>
        <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" className="SvgIcon">
          <path d="M1.822 7.527a1.058 1.058 0 0 0-1.46 1.532l14.384 13.707L29.624 9.071a1.059 1.059 0 0 0-1.432-1.558l-13.42 12.354-12.95-12.34z"></path>
        </Svg>
      </components.DropdownIndicator>
  );
};

const SelectButtons = ({blockButtons, selectOptions, callback, callbackSelect, radioName, checkedField, selectCheckedField, errorText}) => {
  const buttons = blockButtons.map((button, i) => (
      <ButtonDiv className='select-button_container' key={`button-${i}`}>
        <ButtonInput
            type='radio'
            id={`${radioName}-${i}`}
            name={radioName}
            className='select-button'
            value={(typeof button === "object") ? button.value : button}
            onChange={callback}
            data-index={i+1}
            checked={(typeof button === "object") ? String(button.value) === String(checkedField) : button.toString() === checkedField.toString()}
        />
        <ButtonLabel htmlFor={`${radioName}-${i}`} className='select-button_label'>{(typeof button === "object") ? button.label : button}</ButtonLabel>
      </ButtonDiv>
  ));

  let blockSelectButtonsClass = 'form_block-select show-mobile';

  if (selectOptions) blockSelectButtonsClass = 'form_block-select';
  if (errorText && errorText !== '') blockSelectButtonsClass += ' is-invalid';

  return (
      <Theme>
        <BlockSelectButtons className={blockSelectButtonsClass}>
          {buttons}
          {errorText &&
            <Error>{errorText}</Error>
          }
        </BlockSelectButtons>
        {selectOptions &&
          <SelectComponent components={{ DropdownIndicator }} options={selectOptions} styles={selectStyle} onChange={callbackSelect} value={selectCheckedField} />
        }
      </Theme>
  );
};

SelectButtons.propTypes = {
  blockButtons: PropTypes.array.isRequired,
  selectOptions: PropTypes.array,
  callback: PropTypes.func,
  radioName: PropTypes.string.isRequired
};

export default SelectButtons;
