import React from "react";
import styled from "styled-components";

const MarkerMapPin = styled.div`
  position: absolute;
  top: 25px;
  display: inline-block;
  transform: translate(-50%, -100%);
  z-index: 2;
  
  &.is-hovered {
    z-index: 3;
  }
`;

const MarkerPinInner = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  margin-bottom: 15px;
  border-radius: 50%;
  font-size: 14px;
  font-weight: 700;
  background: ${props => props.theme.colors.brandColor};
  
  &:after {
    position: absolute;
    left: 50%;
    bottom: -13px;
    content: "";
    width: 0;
    height: 0;
    z-index: -1;
    transform: translate3d(-50%,0,0);
    transition: border-color .5s ease;
    border-style: solid;
    border-color: ${props => props.theme.colors.brandColor} transparent transparent;
    border-width: 20px 14px 0;
  }
  
  &.is-hovered {
    width: 46px;
    height: 46px;
    
    &:after {
      bottom: -12px;
      border-width: 20px 18px 0;
    }
  }
`;

const Marker = ({ number, activeClass }) => (
    <MarkerMapPin className={activeClass ? 'is-hovered': ''}>
      <MarkerPinInner className={activeClass ? 'is-hovered': ''}>
        {number}
      </MarkerPinInner>
    </MarkerMapPin>
);

export default Marker
