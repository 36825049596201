import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { breakpoints } from "../../../commons/breakpoints";
import Theme from '../../../commons/theme';

const BlockSelectButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
`;

const ButtonDiv = styled.div`
  width: calc(100% - 16px);
  margin: 0 8px 8px;
  
  @media ${breakpoints.sm} {
    width: calc(50% - 16px);
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colors.darkWhite};
`;

const ButtonLabel = styled.label`
  position: relative;
  display: flex;
  justify-content: center;
  height: 100%;
  text-transform: uppercase;
  border: 1px solid ${props => props.theme.colors.benefitBoxTitleBorder};
  
  @media ${breakpoints.sm} {
    justify-content: space-between;
  }
  
  &:hover {
    cursor: pointer;
  }
`;

const CarName = styled.span`
  position: relative;
  width: 100%;
  display: block;
  padding: 16px 8px;
  font-family: 'NouvelRBold';
  font-size: 14px;
  line-height: 20px;
  text-align: center;
`;

const CarCheck = styled.span`
  display: none;
 
  @media ${breakpoints.sm} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 16px;
    background-color: ${props => props.theme.colors.darkWhite};
    border-left: 1px solid ${props => props.theme.colors.benefitBoxTitleBorder};
  }
`;

const CheckIcon = styled.span`
  visibility: hidden;
`;

const ButtonInput = styled.input`
  display: none;
  
  &:checked + ${ButtonLabel} {
    border: 1px solid ${props => props.theme.colors.brandColor};
    box-shadow: 0 0 0 1px ${props => props.theme.colors.brandColor};
    
    ${CarCheck} {
      border-left: 2px solid ${props => props.theme.colors.brandColor};
    }
    
    ${CheckIcon} {
      visibility: visible;
    }
  }
`;

const Svg = styled.svg`
  width: 13px;
  font-weight: 700;
  fill: ${props => props.theme.colors.brandColor}
`;

const NewCars = ({carsArray, checkedField, callback}) => {
  const cars = carsArray.map((car, i) => (
      <ButtonDiv className='car-checkbox' key={`yaer-${i}`}>
        <ButtonContainer className='car-checkbox_container'>
          <ButtonInput
              type='checkbox'
              id={`newCar-${i}`}
              name='newCar'
              className='select-button'
              value={car}
              onChange={callback}
              checked={checkedField === car}
          />
          <ButtonLabel htmlFor={`newCar-${i}`} className='select-button_label'>
            <CarName className='car-checkbox_name'>
              {car}
            </CarName>
            <CarCheck>
              <CheckIcon>
                <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
                  <path d="M29 9.182L23.91 4 12.03 16.092l-5.94-6.046L1 15.228l11.03 11.228z"></path>
                </Svg>
              </CheckIcon>
            </CarCheck>
          </ButtonLabel>
        </ButtonContainer>
      </ButtonDiv>
  ));

  return (
      <Theme>
        <BlockSelectButtons>
          {cars}
        </BlockSelectButtons>
      </Theme>
  );
};

NewCars.propTypes = {
  carsArray: PropTypes.array.isRequired
};

export default NewCars;
