import styled from 'styled-components';
import { breakpoints } from "../../commons/breakpoints";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

const StyledLink = styled(Link)`
  display: block;
  margin: 32px 0 16px;
  padding: 16px;
  color: ${props => props.theme.colors.black};
  font-size: 11px;
  line-height: 11px;
  font-family: 'NouvelRBold';
  text-align: center;
  text-decoration: none;
  background-color: rgba(255, 255, 255, .75);
  vertical-align: middle;
  
  @media ${breakpoints.sm} {
    font-size: 14px;
    line-height: 14px;
  }
`;

const LinkButton = ({text, destination}) => {
  return (
      <StyledLink to={destination} className="link-button">{text}</StyledLink>
  );
};

LinkButton.propTypes = {
  text: PropTypes.string.isRequired,
  destination: PropTypes.string.isRequired
};

export default LinkButton;
