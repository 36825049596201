import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";

const InputContainer = styled.div`
  position: relative;
`;

const InputField = styled.input`
  display: block;
  width: calc(100% - 66px);
  margin: 0;
  padding: 15px 32px;
  font-size: 16px;
  line-height: 18px;
  border: 1px solid ${props => props.theme.colors.benefitBoxTitleBorder};
  border-radius: 2px 0 0 2px;
  
  &:focus {
    outline: none;
  }
`;

const InputPostfix = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px 16px;
  font-family: 'NouvelRBold';
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
`;

const Input = ({postfix, name, value, type, onChange}) => {

  return (
      <InputContainer className='input_container'>
        <InputField className='input' name={name} value={value} type={type} onChange={onChange} />
        {postfix &&
          <InputPostfix className='input_postfix'>
            {postfix}
          </InputPostfix>
        }
      </InputContainer>
  );
};

Input.defaultProps = {
  value: '',
  type: 'text',
};

Input.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  type: PropTypes.string,
  postfix: PropTypes.string
};

export default Input;
