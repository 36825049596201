import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { breakpoints } from "../../commons/breakpoints";
import Theme from '../../commons/theme';

const StyledLink = styled.div`
  position: relative;
  display: inline-block;
  padding: 7px 16px;
  color: ${props => props.theme.colors.footerLinkColor};
  font-size: 16px;
  line-height: 16px;
  font-family: 'NouvelRBold';
  text-decoration: none;
  background-color: ${props => props.theme.colors.darkWhite};
  border: 1px solid ${props => props.theme.colors.darkWhite};
  vertical-align: middle;
  transition: background-color 0.5s ease 0s, color 0.5s ease 0s, border-color 0.5s ease 0s, color 0.5s ease 0s;
  cursor: pointer;
  
  @media ${breakpoints.sm} {
    font-size: 16px;
    line-height: 16px;
  }
  
  &:hover {
    background-color: ${props => props.theme.colors.grayBtnHoverColor};
    border-color: ${props => props.theme.colors.grayBtnHoverColor};
  }
  
  &.is-active {
    padding: 14px 15px;
    color: ${props => props.theme.colors.white};
    background-color: ${props => props.theme.colors.black};
    border: 1px solid ${props => props.theme.colors.black};
    
    &:hover {
    color: ${props => props.theme.colors.black};
      background-color: ${props => props.theme.colors.brandColor};
      border: 1px solid ${props => props.theme.colors.brandColor};
    }
  }
`;

const TextContainer = styled.span`
  position: relative;
  top: -1px;
  padding: 0 32px;
  
  &.link-arrow_text-container--left {
    display: none;
    
    @media ${breakpoints.sm} {
      display: inline-block;
    }
  }
`;

const Svg = styled.svg`
  width: 14px;
  fill: ${props => props.theme.colors.black};
`;

const ButtonForm = ({text, arrowPosition, validFunction, step, activeClass}) => {
  const textContainerClass = (arrowPosition === 'left') ? 'link-arrow_text-container link-arrow_text-container--left' : 'link-arrow_text-container';
  const linkClass = (activeClass) ? 'link-arrow is-active' : 'link-arrow';

  return (
      <Theme>
        <StyledLink className={linkClass} onClick={step}>
          {arrowPosition === 'left' &&
            <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34.075 34.075" className="link-arrow_icon">
              <path d='M24.57 34.075a1.964 1.964 0 0 1-1.396-.577L8.11 18.432a1.972 1.972 0 0 1 0-2.79L23.174.578a1.973 1.973 0 1 1 2.791 2.79l-13.67 13.669 13.67 13.669a1.974 1.974 0 0 1-1.395 3.369z'></path>
            </Svg>
          }
          <TextContainer className={textContainerClass}>{text}</TextContainer>
          {arrowPosition === 'right' &&
            <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 185.343 185.343" className="link-arrow_icon">
              <path d='M51.707 185.343a10.692 10.692 0 0 1-7.593-3.149 10.724 10.724 0 0 1 0-15.175l74.352-74.347L44.114 18.32c-4.194-4.194-4.194-10.987 0-15.175 4.194-4.194 10.987-4.194 15.18 0l81.934 81.934c4.194 4.194 4.194 10.987 0 15.175l-81.934 81.939a10.678 10.678 0 0 1-7.587 3.15z'></path>
            </Svg>
          }
        </StyledLink>
      </Theme>
  );
};

ButtonForm.defaultProps = {
  arrowPosition: 'left',
  activeClass: ''
};

ButtonForm.propTypes = {
  text: PropTypes.string.isRequired,
  arrowPosition: PropTypes.string,
  validFunction: PropTypes.func,
  activeClass: PropTypes.string
};

export default ButtonForm;
