import React from 'react';
import { ThemeProvider } from 'styled-components';

const theme = {
  colors: {
    white: '#fff',
    darkWhite: '#f2f2f2',
    black: '#000',
    brandColor: '#efdf00',
    brandColorLight: '#f8eb4c',
    footerLinkColor: '#3e3f40',
    benefitBoxTitleBorder: '#d9d9d6',
    grayBtnHoverColor: '#e5e5e5',
    selectHighlight: '#ade1f5',
    errorColor: '#e91630',
    errorColor2: '#f0303e',
    materialInputColor: '#8c8c8b'
  },
  sizes: {
    contentWidth: '1280px'
  }
};

const Theme = ({ children }) => (
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;
