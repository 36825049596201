import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { breakpoints } from "../../../commons/breakpoints";
import Theme from '../../../commons/theme';

const Error = styled.div`
  position: absolute; 
  top: 48px;
  display: none;
  margin-top: 2px;
  color: ${props => props.theme.colors.errorColor};
  font-size: 10px;
  line-height: 14px;
`;

const BlockButtons = styled.div`
  position: relative;
  display: none;
  width: 100%;
  margin-bottom: -16px;
  
  @media ${breakpoints.sm} {
    display: flex;
    flex-wrap: wrap;
  }
  
  &.show-mobile {
    display: flex;
    flex-direction: column;
    
    @media ${breakpoints.sm} {
      flex-direction: row;
    }
  }
  
  &.is-invalid {
    ${Error} {
      display: block;
    }
  }
`;

const ButtonDiv = styled.div`
  margin: 0 8px 8px;
  padding-bottom: 8px;
  width: calc(25% - 16px);
  
  .show-mobile & {
    width: calc(100% - 16px);
    padding-bottom: 0;
    
    @media ${breakpoints.sm} {
      width: calc(25% - 16px);
      padding-bottom: 8px;
    }
  }
`;

const ButtonLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-bottom: 16px;
  padding: 4px 0;
  color: ${props => props.theme.colors.footerLinkColor};
  font-family: 'NouvelRRegular';
  font-size: 14px;
  line-height: 14px;
  background-color: ${props => props.theme.colors.darkWhite};
  border: none;
  
  &:hover {
    cursor: pointer;
    font-family: 'NouvelRBold';
    box-shadow: ${props => props.theme.colors.brandColor} 0px 0px 0px 2px inset;
  }
  
  .show-mobile & {
    margin-bottom: 0;
    padding: 10px 0;
    
    @media ${breakpoints.sm} {
      margin-bottom: 16px;
      padding: 4px 0;
    }
  }
  
  .is-invalid & {
    color: ${props => props.theme.colors.errorColor};
  }
`;

const ButtonInput = styled.input`
  position: absolute;
  left: -9999px;
  
  &:checked + ${ButtonLabel} {
    font-family: 'NouvelRBold';
    box-shadow: ${props => props.theme.colors.brandColor} 0px 0px 0px 2px inset;
  }
`;

const Buttons = ({blockButtons, callback, radioName, checkedField, errorText}) => {
  const buttons = blockButtons.map((button, i) => (
      <ButtonDiv className='select-button_container' key={`button-${i}`}>
        <ButtonInput
            type='radio'
            id={`${radioName}-${i}`}
            name={radioName}
            className='select-button'
            value={(typeof button === "object") ? button.value : button}
            onChange={callback}
            data-index={i+1}
            checked={(typeof button === "object") ? String(button.value) === String(checkedField) : button.toString() === checkedField.toString()}
        />
        <ButtonLabel htmlFor={`${radioName}-${i}`} className='select-button_label'>{(typeof button === "object") ? button.label : button}</ButtonLabel>
      </ButtonDiv>
  ));

  let blockButtonsClass = 'form_block-select show-mobile';

  if (errorText && errorText !== '') blockButtonsClass += ' is-invalid';

  return (
      <Theme>
        <BlockButtons className={blockButtonsClass}>
          {buttons}
          {errorText &&
          <Error>{errorText}</Error>
          }
        </BlockButtons>
      </Theme>
  );
};

Buttons.propTypes = {
  blockButtons: PropTypes.array.isRequired,
  callback: PropTypes.func,
  radioName: PropTypes.string.isRequired
};

export default Buttons;
