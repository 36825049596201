import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import { breakpoints } from "../../commons/breakpoints";
import ButtonPrimary from '../_shared/ButtonPrimary';

const Wrapper = styled.div`
  width: 80%;
  margin: 0 auto;
  text-align: center;
`;

const IconWrapper = styled.div`
  position: relative;
  width: 158px;
  height: 158px;
  margin: 60px auto 30px;
  background-color: ${props => props.theme.colors.errorColor2};
  border-radius: 500%;
`;

const Icon = styled.svg`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  fill: ${props => props.theme.colors.white};
  width: 110px;
  height: 110px;
`;

const ErrorText = styled.p`
  font-size: 22px;
  line-height: 27px;
`;

const ButtonContainer = styled.div`
  position: relative;
  left: 0;
  margin: 40px auto;
  width: 100px;
  
  @media ${breakpoints.md} {
    left: -10px;
  }
`;

const Error = ({text}) => {
  const { t } = useTranslation();

  if (text === null || text === '') {
    text = t('responseErrorDefault')
  }

  return (
      <Wrapper>
        <IconWrapper>
          <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 110 110">
            <g xmlns="http://www.w3.org/2000/svg" id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="blad-REN-2" transform="translate(-905.000000, -84.000000)">
                <rect fill="#FFFFFF" x="0" y="0" width="1920" height="886"/>
                <g id="Group" transform="translate(881.000000, 60.000000)">
                  <circle id="Oval" fill="#F0303E" cx="79" cy="79" r="79"/>
                  <g id="24ikon-/-CANCEL" transform="translate(24.000000, 24.000000)" fill="#FFFFFF" fillRule="nonzero">
                    <polygon id="Path" points="18.3333333 87.4429842 50.8471846 54.9291328 18.3333333 22.4152815 22.4152815 18.3333333 54.9291328 50.8471846 87.471331 18.3333333 91.5532792 22.2735472 59.011081 54.9291328 91.6666667 87.5847185 87.5847185 91.6666667 54.9291328 59.011081 22.4152815 91.5249324"/>
                  </g>
                </g>
              </g>
            </g>
          </Icon>
        </IconWrapper>
        <ErrorText>{text}</ErrorText>
        <ButtonContainer>
          <ButtonPrimary text="OK" destination="/" />
        </ButtonContainer>
      </Wrapper>
  );
};

Error.propTypes = {
  text: PropTypes.string
};

export default Error;
