import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import axios from "axios";
import styled from 'styled-components';
import Title from './_shared/Title';
import Loader from "./_shared/Loader";
import {breakpoints} from "../../commons/breakpoints";
import FormButtons from "./_shared/FormButtons";
import Error from './Error';
import {RaportDL} from '../_shared/DataLayer';
import PropTypes from "prop-types";

const Section = styled.section`
  max-width: ${props => props.theme.sizes.contentWidth};
  margin: 0 8px;
  padding: 0;
  
  @media ${breakpoints.sm} {
    margin: 0 auto;
  }
  
  @media ${breakpoints.lg} {
    min-height: calc(100vh - 271px);
  }
`;

const SectionTitle = styled.p`
  display: none;
  margin-top: 0;
  font-family: 'NouvelRBold';
  font-size: 13px;
  line-height: 18px;
  
  @media ${breakpoints.sm} {
    display: block;
  }
  
  @media ${breakpoints.md} {
    font-size: 14px;
  line-height: 20px;
  }
`;

const CarsWrapper = styled.div`
  border: 1px solid ${props => props.theme.colors.darkWhite};
  box-shadow: 0 1px 0 0 rgb(0 0 0 / 25%);
`;

const ButtonLabel = styled.label`
  display: block;
  width: calc(100% - 32px);
  height: 100%;
  margin: 0;
  padding: 12px 16px;
  font-family: 'NouvelRRegular';
  font-size: 11px;
  line-height: 16px;
  border-top: 1px solid ${props => props.theme.colors.darkWhite};
  
  @media ${breakpoints.sm} {
    font-size: 14px;
    line-height: 20px;
  }
  
  &:hover {
    cursor: pointer;
    background-color: ${props => props.theme.colors.darkWhite};
  }
`;

const ButtonDiv = styled.div`
  width: 100%;
  
  &:first-child {
    ${ButtonLabel} {
      border-top: none;
    }
  }
`;

const ButtonInput = styled.input`
  position: absolute;
  left: -9999px;
  
  &:checked + ${ButtonLabel} {
    background-color: ${props => props.theme.colors.darkWhite};
    box-shadow: ${props => props.theme.colors.brandColor} 0px 0px 0px 2px inset;
  }
`;

const API_KEY = process.env.REACT_APP_API_KEY;
const API_URL = process.env.REACT_APP_API_URL;

const FormStep4 = ({step, setStep, data, setData}) => {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const carsArray = [];

  useEffect(() => {
    setData({
      type: 'UPDATE_LOADER',
      payload: {
        loader: true,
      },
    });

    axios.get(`${API_URL}get-versions?makeId=${data.carData.brand.value}&modelId=${data.carData.model}&date=${data.carData.year}.${data.carData.monthIndex}&fuel=${data.carData.fuel}`, {
      headers: {
        'apiKey': `${API_KEY}`
      }
    })
        .then(function(response) {
          if (response.data.Success) {
            setData({
              type: 'UPDATE_CAR_DATA',
              payload: {
                carModels: response.data.Data
              },
            });
            setError(null);
          } else {
            setError(response.data.Message);
          }
          setData({
            type: 'UPDATE_LOADER',
            payload: {
              loader: false,
            },
          });
        })
        .catch(function(error) {
          console.log(error);
          setError(t('responseErrorDefault'));
          setData({
            type: 'UPDATE_LOADER',
            payload: {
              loader: false,
            },
          });
        });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (data.carData.carModels) {
    data.carData.carModels.forEach((car) => {
      carsArray.push({label: car.Value, value: car.Key});
    });
  }

  const handleChange = (e) => {
    setData({
      type: 'UPDATE_CAR_DATA',
      payload: {
        car: e.target.value
      },
    });

    setData({
      type: 'UPDATE_NEXT_BUTTON',
      payload: {
        buttonStep4: true
      },
    });

    RaportDL('mileage', 'step-7');

    setStep(step + 1);
  };

  const validHandler = () => {
    if (!data.carData.car) {
      setData({
        type: 'UPDATE_ERROR',
        payload: {
          error: true
        },
      });
    } else {
      setData({
        type: 'UPDATE_ERROR',
        payload: {
          error: false
        },
      });
      setStep(step + 1);
    }
  };

  const buttons = carsArray.map((button, i) => (
      <ButtonDiv className='select-button_container' key={`car-${i}`}>
        <ButtonInput
            type='radio'
            id={`car-${i}`}
            name='car'
            className='select-button'
            value={button.value}
            onChange={handleChange}
            checked={Number(button.value) === Number(data.carData.car)}
        />
        <ButtonLabel htmlFor={`car-${i}`} className='select-button_label'>{(typeof button === "object") ? button.label : button}</ButtonLabel>
      </ButtonDiv>
  ));

  return (
      <>
        <Loader loaderClass={data.loader} />
        {error &&
          <Error text={error} />
        }
        {!error &&
          <>
            <Section>
              <Title title={t('formTitleCar')}/>
              <SectionTitle className='cars_title'>{t('formCarsTitle')}</SectionTitle>
              <CarsWrapper className='cars'>
                {buttons}
              </CarsWrapper>
              <FormButtons next={validHandler} nextActive={data.stepsNextButtons.buttonStep4} prev={() => setStep(step - 1)} error={data.error}/>
            </Section>
          </>
        }
      </>
  );
};

FormStep4.propTypes = {
  step: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
};

export default FormStep4;
