import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import _ from 'lodash';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import {LOCALES, I18N_LS_KEY} from './commons/constants';
import path from './commons/path';

if (!localStorage[I18N_LS_KEY]) { // Entry point of default language.
  localStorage[I18N_LS_KEY] = LOCALES['pl-PL'].lang;
}

const detectionOptions = {
  order: ['path', 'cookie', 'navigator', 'localStorage', 'subdomain', 'queryString', 'htmlTag'],
  lookupFromPathIndex: 1
};

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(LanguageDetector)
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: LOCALES['pl-PL'].lang,
    // debug: process.env.NODE_ENV !== 'master',
    debug: false,
    detection: detectionOptions,
    whitelist: _.map(LOCALES, locale => locale.lang),
    transSupportBasicHtmlNodes: true,
    interpolation: {
      escapeValue: false
    },
    backend: {
      loadPath: path('/locales/{{lng}}/{{ns}}.json')
    }
  });

export default i18n;
