import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import i18n from './i18n';

i18n.init().then(
    () => ReactDOM.render(
        <Suspense fallback={<div></div>}>
          <App />
        </Suspense>,
        document.getElementById('root'))
);
