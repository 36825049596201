const size = {
  xs: '320px',
  sm: '768px',
  md: '892px',
  lg: '1280px',
};

export const breakpoints = {
  xs: `(min-width: ${size.xs})`,
  sm: `(min-width: ${size.sm})`,
  md: `(min-width: ${size.md})`,
  lg: `(min-width: ${size.lg})`
};
